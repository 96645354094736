export const DefaultUser = {
  vorName: '',
  nachName: '',
  city: '',
  kontaktDaten: {
    telefon: '',
    email: '',
  },
  autoDaten: {
    marke: '',
    modell: '',
    baujahr: '',
    kilometerstand: '',
    bilder: []
  },
  textFelder: {
    bekannteMaengel: '',
    weitereAngaben: '',
    datenschutzOk: false,

  }
}

export const UserReducer = (user, { type, payload }) => {
  switch (type) {
    case 'UPDATE_PERSONAL_INFO':
      return {
        ...user,
        ...payload,
      }
    case 'UPDATE_KONTAKT_INFO':
      return {
        ...user,
        kontaktDaten: {
          ...user.kontaktDaten,
          ...payload,
        },
      }

    case 'UPDATE_AUTODATEN':
      return {
        ...user,
        autoDaten: {
          ...user.autoDaten,
          ...payload,
        },
      }

    case 'UPDATE_TEXTFELDER':
      return {
        ...user,
        textFelder: {
          ...user.textFelder,
          ...payload,
        },
      }

    default:
      return user
  }
}