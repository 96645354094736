import React from 'react'
import Helmet from 'react-helmet'
// eslint-disable-next-line
import { graphql } from 'gatsby'

import Layout from '../components/layout'

// import blocks
import Contact from '../components/blocks/contact'
import CTA from '../components/blocks/cta'
import Form from '../components/blocks/form'
import Gallery from '../components/blocks/gallery'
import Hero from '../components/blocks/hero'
import Pfeiltext from '../components/blocks/pfeiltext'
import TextAndPic from '../components/blocks/textandpic'

class BlocksTemplate extends React.Component {
  
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          htmlAttribute={{ lang: 'de' }}
          title={post.frontmatter.title !== "Home" ? `${post.frontmatter.title} | ${siteTitle}` : `${siteTitle}`}
        />
        {post.frontmatter.blocks.map((block, index) => {
          switch (block.component) {
            case 'cta':
              return <CTA block={block} index={index} />
            case 'contact':
              return <Contact block={block} index={index} />
            case 'form':
              return <Form block={block} index={index} />
            case 'gallery':
              return <Gallery block={block} index={index} />
            case 'hero':
              return <Hero block={block} index={index} />
            case 'arrows_text':
              return <Pfeiltext block={block} index={index} />
            case 'textandpic':
              return <TextAndPic block={block} index={index} />
            default:
              return ''
          }
        })}
      </Layout>
    )
  }
}

export default BlocksTemplate

export const pageQuery = graphql`
  query BlockPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        blocks {
          blocks {
            block {
              big_text
              small_title
            }
          }
          button {
            text
            type
            url
          }
          component
          cta {
            title
            url
          }
          firmenname
          galerie {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
          header_picture {
            picture {
              childImageSharp {
                fluid {
                  srcSet
                }
              }
            }
            picture_alt_text
          }
          subtitle
          template
          text
          thank_you_subtitle
          thank_you_title
          three_items {
            punkt {
              icon {
                alt_text
                bild {
                  childImageSharp {
                    fluid {
                      srcSet
                    }
                  }
                }
              }
              text
            }
          }
          titel
          title
          title_big
        }
        template
        title
      }
    }
  }
`