import React from 'react'

class TextAndPic extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { block, index } = this.props;

    return (
      <section className="is-spaced-top two-col mb-6">
        <div className="container">
          <div className={"columns"}>
            <div className="column">
              {block.picture && <img srcset={block.picture.childImageSharp.fluid.srcSet} alt={block.alt_text} />}
            </div>
            <div className="column">
              { index === 0 ? <h1 className="title is-1">{block.title}</h1> : <h2 className="title is-2">{block.title}</h2>}
              <p>{block.text}</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TextAndPic