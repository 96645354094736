import React from 'react'
import Pfeil from '../../images/svgs/rechts.svg'

class Pfeiltext extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { block, index } = this.props;

    console.log("block", block)

    return (
      <section className="is-spaced-top" id="ueber-uns">
        <div className="container">
          <div className="columns text-and-arrows">
            <div className="column">
              { index === 0 ? <h1 className="title is-1">{block.titel}</h1> : <h2 className="title is-2">{block.titel}</h2>}
              {block.blocks.map((item, index) => {
                return(
                  <div className={"columns pt-6"}>
                    <div className="column columns">
                      <div className="column is-2"><Pfeil /></div>
                      <div className="column is-8">
                        <strong className="subtitle is-5">
                          {item.block.small_title}
                        </strong>
                      </div>
                      
                    </div>
                    <div className="column is-5">
                      <strong className="title is-6">
                        {item.block.big_text}
                      </strong>
                      <hr/>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Pfeiltext