import React from 'react'
import Slider from "react-slick";

class Gallery extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { block, index } = this.props;

    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1408,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true
          }
        }
      ]
    };

    return (
      <section className="is-spaced-top mb-6" id="galerie">
        <div className="container">
          <div className={"columns"}>
            <div className="column">
            { index === 0 ? <h1 className="title is-1 mb-6">{block.titel}</h1> : <h2 className="title is-2 mb-6">{block.titel}</h2>}

              <Slider {...settings}>
                {block.galerie.map((item, index) => {
                  return(
                    <div className="slider-img item">
                      <div>
                        {item && <img srcset={item.childImageSharp.fluid.srcSet} alt={"ZJ-Automobile - Bild eines Gebrauchtwagen aus Karben Nr." + index} />}
                      </div>
                    </div>
                  )
                })}
              </Slider>
              
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Gallery