import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useForm } from "react-hook-form"

const KontaktDaten = ({ step, setStep, user, setUser }) => {
  const { register, errors, handleSubmit } = useForm()
  // handle onchange

  const handleKontaktDaten = ({ target }) => {
    setUser({
      type: 'UPDATE_KONTAKT_INFO',
      payload: { [target.name]: target.value },
    })
  }

  const handleSubmitSteps = () => {
    if (Object.keys(errors).length > 0) return

    //handle http request
    setStep(step + 1)
  }

  const onSubmit = (() => {
    handleSubmitSteps();
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <div class="field">
        <label class="label">Telefon</label>
        <div class="control">
          <input
            type="text"
            className="input"
            placeholder="0123 456 789 0"
            label="Telefon"
            name="telefon"
            value={user.kontaktDaten.telefon}
            onChange={(e) => handleKontaktDaten(e)}
            ref={register({ required: true })}
          />
        </div>
        {errors.telefon && <p class="help is-danger pb-0">Bitte füllen Sie dieses Feld aus</p>}
      </div>

      <div class="field">
        <label class="label">E-Mail</label>
        <div class="control">
          <input
            type="email"
            className="input"
            placeholder="michael@jackson.de"
            label="E-Mail"
            name="email"
            value={user.kontaktDaten.email}
            onChange={(e) => handleKontaktDaten(e)}
            ref={register({ required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })}
          />
        </div>
        {errors.email && <p class="help is-danger pb-0">Bitte füllen Sie dieses Feld aus</p>}
      </div>

      <div class="field is-grouped is-justify-content-space-between">
        <div class="control">
          <button class="button is-link is-light" onClick={() => setStep(step - 1)}>Zurück</button>
        </div>
        <div class="control">
          <button class="button is-link" type="submit">Nächster Schritt</button>
        </div>
      </div>
    </form>
  )
}
KontaktDaten.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  user: PropTypes.object,
  setUser: PropTypes.func,
}
export default KontaktDaten