import React from 'react'
import UserDetails from './steps/userdetails'
import KontaktDaten from './steps/kontaktdaten'
import AutoDaten from './steps/autodaten'
import TextFelder from './steps/textfelder'
import Completed from './steps/completed'

const FormSteps = (props, step, setStep, user, setUser) => [
  {
    title: 'Ihr Name',
    content: (
      <UserDetails
        setStep={setStep}
        step={step}
        user={user}
        setUser={setUser}
      />
    ),
  },
  {
    title: 'Ihre Kontaktdaten',
    content: (
      <KontaktDaten setStep={setStep} step={step} user={user} setUser={setUser} />
    ),
  },
  {
    title: 'Ihre Fahrzeugdaten',
    content: (
      <AutoDaten setStep={setStep} step={step} user={user} setUser={setUser} />
    ),
  },
  {
    title: 'Weitere Informationen',
    content: (
      <TextFelder setStep={setStep} step={step} user={user} setUser={setUser} />
    ),
  },
  {
    title: 'Fertig!',
    content: <Completed user={user} props={props} />,
  },
]
export default FormSteps