import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from "react-hook-form"

const UserDetails = ({ step, setStep, user, setUser }) => {
  const { register, errors, handleSubmit } = useForm()

  // handle onchange
  const handleUser = ({ target }) => {
    setUser({
      type: 'UPDATE_PERSONAL_INFO',
      payload: { [target.name]: target.value },
    })
  }
  const handleSubmitSteps = () => {
    //check for errors
    if (Object.keys(errors).length > 0) return
    setStep(step + 1)
  }

  const onSubmit = (() => {
    handleSubmitSteps();
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div class="field">
        <label class="label">Vorname</label>
        <div class="control">
          <input
            type="text"
            className="input"
            placeholder="Michael"
            name="vorName"
            value={user.vorName}
            label="Vorname"
            onChange={(e) => handleUser(e)}
            ref={register({ required: true })}
          />
        </div>
        {errors.vorName && <p class="help is-danger pb-0">Bitte füllen Sie dieses Feld aus</p>}
      </div>

      <div class="field">
        <label class="label">Nachname</label>
        <div class="control">
          <input
            type="text"
            className="input"
            placeholder="Jackson"
            name="nachName"
            value={user.nachName}
            label="Nachname"
            onChange={(e) => handleUser(e)}
            ref={register({ required: true })}
          />
        </div>
        {errors.nachName && <p class="help is-danger pb-0">Bitte füllen Sie dieses Feld aus</p>}
      </div>

      <div className="field city">
        <label className="label">Stadt</label>
        <div className="control">
          <input
            className="input mb-4"
            value={user.city}
            name="city"
            type="text" 
            placeholder="Frankfurt am Main"
            ref={register({ required: false })}
          />
        </div>
      </div>

      <div class="field">
        <div class="control">
          <button class="button is-link" type="submit">Nächster Schritt</button>
        </div>
      </div>
    </form>
  )
}
UserDetails.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  user: PropTypes.object,
  setUser: PropTypes.func,
}
export default UserDetails