import React from 'react'
import Pfeil from '../../images/svgs/rechts.svg'
import Mail from '../../images/svgs/mail-circle.svg'
import Phone from '../../images/svgs/phone-circle.svg'
import SiteConfig from '../../../site-config.json'

class Contact extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { block, index } = this.props;

    return (
      <section className="is-spaced-top is-spaced-btm" id="kontakt">
        <div className="container">
          <div className="columns contact is-multiline">
            <div className="column is-5-desktop is-12-tablet">
              { index === 0 ? <h1 className="title is-1">{block.title}</h1> : <h2 className="title is-2">{block.title}</h2>}
              <p>
                {block.text}
              </p>
            </div>
            <div className="column is-1 is-hidden-mobile"></div>
            <div className="column is-6-desktop is-12-tablet">
              <div className="mail columns">
                <div className="column is-2 is-hidden-tablet">
                  <Pfeil className="mt-3" />
                </div>
                <div className="column is-3-desktop is-2-tablet">
                  <Mail />
                </div>
                <div className="column is-7-desktop is-10-tablet mt-5">
                  <span>E-Mail: <a href={"mailto:"+SiteConfig["e-mail"]} className="thick">{SiteConfig["e-mail"]}</a></span>
                </div>
              </div>
              
              <div className="phone columns pt-6">
                <div className="column is-2 is-hidden-tablet">
                  <Pfeil className="mt-3" />
                </div>
                <div className="column is-3-desktop is-2-tablet">
                <Phone />
                </div>
                <div className="column is-7-desktop is-10-tablet mt-5">
                  <span>Telefon: <a href={"tel:"+SiteConfig.phone} className="thick">{SiteConfig.phone}</a></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact