import { Link } from 'gatsby';
import React from 'react'

class CTA extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { block, index } = this.props;

    return (
      <section className="pt-6 pb-6 has-text-centered has-background-primary">
        <div className="container">
          <div className={"columns"}>
            <div className="column">
              <div className="pb-6 pt-6">
                { index === 0 ? <h1 className="title is-1">{block.title}</h1> : <h2 className="title is-2">{block.title}</h2>}
              </div>
              <div className="pb-6">
                {block.button.type === "internal" ? 
                  <Link to={block.button.url} className="button is-info is-big">{block.button.text}</Link> :
                  <a href={block.button.url} target="_blank" className="button is-info is-big">{block.button.text}</a>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CTA