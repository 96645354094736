import React from 'react'
import Danke from '../../../../images/svgs/danke.svg'

const KontaktDaten = (props) => {
  console.log("prps",props);
  return (
    <div className="has-text-centered">
      <Danke className="thanks-svg" />
      <strong className="title is-3">{props.props.block.thank_you_title}</strong>
      <p>{props.props.block.thank_you_subtitle}</p>
    </div>
    
  )
}
export default KontaktDaten