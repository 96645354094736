import React, { useReducer, useState } from 'react'
import { UserReducer, DefaultUser } from './form/user-reducer'

import FormSteps from './form/form-steps'

const Form = (block, index) => {
  let [step, setStep] = useState(0)
  const [user, setUser] = useReducer(UserReducer, DefaultUser)
  let steps = FormSteps(block, step, setStep, user, setUser)
  let currStep = step;

  return (
    <section className="is-spaced-top mb-6" id="formular">
      <div className="container">
        <div className={"columns"}>
          <div className="column has-text-centered">

            <h2 className="title is-2">Verkaufen Sie uns<br className="is-hidden-mobile"/> Ihren Gebrauchtwagen</h2>
            <div class="steps">
              {steps.map((step, index) => {
                return(
                  <div class={"step-item " + (currStep >= index ? "is-completed" : "")}>
                    <div class="step-marker">{index+1}</div>
                    <div class="step-details">
                      <p class="step-title">{step.title}</p>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="columns is-centered is-mobile">
              <div className="column is-one-third-desktop is-three-quarters-tablet greyblock has-text-left">{steps[`${step}`].content}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Form