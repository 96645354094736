import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useForm } from "react-hook-form";

const TextFelder = ({ step, setStep, user, setUser }) => {
  const { register, errors, handleSubmit } = useForm()

  const handleTextFelder = ({ target }) => {
    setUser({
      type: 'UPDATE_TEXTFELDER',
      payload: { [target.name]: target.value },
    })
  }

  const handleSubmitSteps = () => {
    if (Object.keys(errors).length > 0) return
    //submit form

    var baseEncodedImages = [];

    console.log(user.autoDaten.bilder)

    var convertData = new Promise((resolve, reject) => {
      if(user.autoDaten.bilder.length === 0){
        resolve()
      }
      else{
        for (let index = 0; index < user.autoDaten.bilder.length; index++) {
          const item = user.autoDaten.bilder[index];
    
          const reader = new FileReader();
          reader.readAsDataURL(item); 
          reader.onloadend = function() {
            const base64data = reader.result;        
            baseEncodedImages.push(base64data);
  
            if(index === user.autoDaten.bilder.length-1){
              resolve()
            }
          }
        }
      }
    })
    
    convertData.then(() => {
      console.log("done", baseEncodedImages);

      fetch('https://krisnetics.com/composer/mail-zj.php', {
        method: 'POST',
        mode: "cors",
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          name: user.vorName + " " + user.nachName,
          stadt: user.city,
          email: user.kontaktDaten.email,
          telefon: user.kontaktDaten.telefon,
          marke: user.autoDaten.marke,
          modell: user.autoDaten.modell,
          baujahr: user.autoDaten.baujahr,
          kilometerstand: user.autoDaten.kilometerstand,
          bilder: baseEncodedImages,
          bekannteMaengel: user.textFelder.bekannteMaengel,
          weitereAngaben: user.textFelder.weitereAngaben,
          datenschutzOk: (user.textFelder.datenschutzOk === "on" ? true : false)
        },)
      }).then(function(response) {
        return response.text();
      }).then(function(data) {
        console.log(data);
        if(data.toLowerCase().includes("error")){
          console.log(data);
        }
        else{

          if (typeof window !== "undefined") {
            if (window._paq != null) { 
              window._paq.push(['trackEvent', 'Contact', 'Contact form sent']);
            }
          }

          setStep(step + 1);
        }
      });
    });
  }

  const onSubmit = (() => {
    handleSubmitSteps();
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div class="field">
        <label class="label">Bekannte Mängel am Fahrzeug</label>
        <div class="control">
          <textarea
            class="textarea" 
            placeholder="Motorschaden, Steinschlag..."
            type="text"
            label="Bekannte Mängel"
            name="bekannteMaengel"
            value={user.textFelder.bekannteMaengel}
            onChange={(e) => handleTextFelder(e)}
            ref={register({ required: true })}
          />
        </div>
        {errors.bekannteMaengel && <p class="help is-danger pb-0">Bitte füllen Sie dieses Feld aus</p>}
      </div>

      <div class="field">
        <label class="label">Weitere Angaben & Ihre Nachricht an uns</label>
        <div class="control">
          <textarea
            type="text"
            className="textarea"
            label="Weitere Angaben & Ihre Nachricht an uns"
            placeholder="Ich würde gerne mein Auto verkaufen..."
            name="weitereAngaben"
            value={user.textFelder.weitereAngaben}
            onChange={(e) => handleTextFelder(e)}
            ref={register({ required: true })}
          />
        </div>
        {errors.weitereAngaben && <p class="help is-danger pb-0">Bitte füllen Sie dieses Feld aus</p>}
      </div>

      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input 
              type="checkbox"
              label="Ich habe die Datenschutzerklärung gelesen und akzeptiere diese"
              name="datenschutzOk"
              checked={user.textFelder.datenschutzOk}
              onChange={(e) => handleTextFelder(e)}
              ref={register({ required: true })}
            />&nbsp;
            Ich habe die <a href="/datenschutz" target="_blank">Datenschutzerklärung</a> gelesen und akzeptiere diese
          </label>
        </div>
        {errors.datenschutzOk && <p class="help is-danger pb-0">Bitte lesen Sie die Datenschutzerklärung und bestätigen Sie, dass Sie dies getan haben und diese akzeptieren</p>}
      </div>
      
      <div class="field is-grouped is-justify-content-space-between">
        <div class="control">
          <button class="button is-link is-light" onClick={() => setStep(step - 1)}>Zurück</button>
        </div>
        <div class="control">
          <button class="button is-link" type="submit">Abschicken</button>
        </div>
      </div>
    </form>
  )
}
TextFelder.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  user: PropTypes.object,
  setUser: PropTypes.func,
}
export default TextFelder