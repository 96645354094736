import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from "react-hook-form"

const AutoDaten = ({ step, setStep, user, setUser }) => {
  const { register, errors, handleSubmit } = useForm()

  const handleAutoDaten = ({ target }) => {
    setUser({
      type: 'UPDATE_AUTODATEN',
      payload: { [target.name]: target.value },
    })
  }

  const handleSubmitSteps = () => {
    if (Object.keys(errors).length > 0) return

    //handle http request
    setStep(step + 1)
  }

  const onSubmit = ((data) => {
    user.autoDaten.bilder = data.bilder;
    handleSubmitSteps();
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <div class="field">
        <label class="label">Marke</label>
        <div class="control">
          <input
            type="text"
            className="input"
            placeholder="BMW"
            label="Marke"
            name="marke"
            value={user.autoDaten.marke}
            onChange={(e) => handleAutoDaten(e)}
            ref={register({ required: true })}
          />
        </div>
        {errors.marke && <p class="help is-danger pb-0">Bitte füllen Sie dieses Feld aus</p>}
      </div>

      <div class="field">
        <label class="label">Modell</label>
        <div class="control">
          <input
            type="text"
            className="input"
            placeholder="3er"
            label="Modell"
            name="modell"
            value={user.autoDaten.modell}
            onChange={(e) => handleAutoDaten(e)}
            ref={register({ required: true })}
          />
        </div>
        {errors.modell && <p class="help is-danger pb-0">Bitte füllen Sie dieses Feld aus</p>}
      </div>

      <div class="field">
        <label class="label">Baujahr</label>
        <div class="control">
          <input
            type="text"
            className="input"
            placeholder="2020"
            label="Baujahr"
            name="baujahr"
            value={user.autoDaten.baujahr}
            onChange={(e) => handleAutoDaten(e)}
            ref={register({ required: true })}
          />
        </div>
        {errors.baujahr && <p class="help is-danger pb-0">Bitte füllen Sie dieses Feld aus</p>}
      </div>

      <div class="field">
        <label class="label">Kilometerstand</label>
        <div class="control">
          <input
            type="text"
            className="input"
            placeholder="10.000"
            label="Kilometerstand"
            name="kilometerstand"
            value={user.autoDaten.kilometerstand}
            onChange={(e) => handleAutoDaten(e)}
            ref={register({ required: true })}
          />
        </div>
        {errors.kilometerstand && <p class="help is-danger pb-0">Bitte füllen Sie dieses Feld aus</p>}
      </div>

      <div class="field">
        <label class="label">Bilder hochladen (optional)</label>
        <div class="control">
        <div class="file has-name is-boxed">
          <label class="file-label">
            <input class="file-input" accept=".jpg, .JPG, .png, .PNG, .gif" type="file" name="bilder" multiple ref={register({ required: false })} />
            <span class="file-cta">
              <span class="file-label">
                Bilder auswählen
              </span>
            </span>
          </label>
        </div>
        </div>
        <p class="help pb-0">Wählen Sie die Bilder aus und klicken Sie auf "Nächster Schritt".<br/>Laden Sie bitte nur folgende Formate hoch: .jpg, .png, .gif</p>
      </div>
      
      <div class="field is-grouped is-justify-content-space-between">
        <div class="control">
          <button class="button is-link is-light" onClick={() => setStep(step - 1)}>Zurück</button>
        </div>
        <div class="control">
          <button class="button is-link" type="submit">Nächster Schritt</button>
        </div>
      </div>
    </form>
  )
}
AutoDaten.propTypes = {
  step: PropTypes.number,
  setStep: PropTypes.func,
  user: PropTypes.object,
  setUser: PropTypes.func,
}
export default AutoDaten