import { Link } from 'gatsby'
import React from 'react'

import scrollTo from 'gatsby-plugin-smoothscroll';

class Hero extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { block } = this.props;

    return (
      <header id="hero">
        <div className="container">
          {block.header_picture && <img className="header-pic" srcset={block.header_picture.picture.childImageSharp.fluid.srcSet} alt={block.header_picture.picture_alt_text} />}
          <div className="columns">
            <div className="column">
              <div className="company-name">{block.firmenname}</div>
              <div className="text-wrapper">
                <h1 className="title is-1">
                  {block.title_big}<sup>*</sup>
                </h1>
                {block.subtitle && <p className="subtitle">{block.subtitle}</p>}
                {block.cta && <Link 
                    className="button is-info is-big" 
                    to={block.cta.url}
                    onClick={() => {scrollTo(block.cta.url)}}
                  >
                    {block.cta.title}
                  </Link>}
              </div>

              <div className="three-items columns is-multiline is-mobile">
                {block.three_items.map((item, index) => {
                  return(
                    <div className={"item column " + (index === 0 ? "is-12" : "is-6")}>
                      <div className="white-circle">
                        {item.punkt.icon && <img srcset={item.punkt.icon.bild.childImageSharp.fluid.srcSet} alt={item.punkt.icon.alt_text} />}
                      </div>
                      <span>{item.punkt.text}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Hero